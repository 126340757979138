@tailwind base;
@tailwind components;
@tailwind utilities;

/* .App {
  text-align: center;
} */

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

* {
  -webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* scrollbar-width: none;
  -webkit-scrollbar-width: none;


  -webkit-scrollbar-thumb {
    width: 0;
    background-color: #888;
  }

  -webkit-scrollbar-track {
    width: 0;
    background-color: #f1f1f1;
  } */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.black-border {
  border: 2px solid black !important;
  color: black !important;
}

#scrolling_div::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
}

#scrolling_div::-webkit-scrollbar-thumb {
  background: #18475A;
  border-radius: 2px;
}

#scrolling_div::-webkit-scrollbar-track {
  background: #d5d4d4; 
  border-radius: 2px
}